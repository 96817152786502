import React, { Component } from 'react'

import {
  Grid,
  WithStyles,
  withStyles,
  Theme,
  createStyles,
} from '@material-ui/core'

import {
  Site,
  Asset,
  FlowMeter,
  PumpControl as StorePumpControl,
  StaticPressure,
} from '../../store/Site'
import {
  SiteState,
  TelemetryState,
  TelemetryMessage,
  TelemetryByTelemetryTypeAndAssetId,
} from '../../store/SiteDetails'
import {
  HistoricalDataState,
  HistoricalDataByTelemetryType,
  HistoricalDataByTelemetryTypeAndAssetId,
} from '../../store/HistoricalData'
import Pressure from '../sensors/Pressure'
import TankLevel from '../sensors/TankLevel'
import Flow from '../sensors/Flow'
import { TimeRange } from '../misc/TimeRange'
import { Threshold } from '../../api/thresholdClient'
import { BreakpointValues } from '@material-ui/core/styles/createBreakpoints'
import { TelemetryType } from 'api/apiservice'
import { StatusRange } from 'api/alertservice'
import HeaterTemperature from 'components/sensors/HeaterTemperature'
import CrankRevolutions from 'components/sensors/CrankRevolutions'
import LiquidFlow from 'components/sensors/LiquidFlow'
import GenericSensor from 'components/sensors/GenericSensor'
import LinePsi from 'components/sensors/LinePsi'
import StrokesPerMinute from 'components/sensors/StrokesPerMinute'
import Vibration from 'components/sensors/Vibration'
import KnockoutPressure from 'components/sensors/KnockoutPressure'
import BalancedTank from 'components/sensors/BalancedTank'
import FlareStatus from 'components/sensors/FlareStatus'
import PumpControl from 'components/sensors/PumpControl'

const flowTelemetryTypes = [
  TelemetryType.Flow,
  TelemetryType.DiffPressure,
  TelemetryType.StaticPressure,
  TelemetryType.StaticTemp,
]

const linePSITelemetryTypes = [
  TelemetryType.StaticPressure,
  TelemetryType.StaticTemp,
]
const totalFlowTelemetryTypes = [TelemetryType.TotalFlow]
const pumpTelemetryTypes = [TelemetryType.PumpControl]

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1),
    },
    notificationsWithFilter: {
      display: 'grid',
      alignItems: 'start',
    },
    tabs: {
      flexGrow: 1,
    },
  })

interface Props {
  readonly site: SiteState
  readonly telemetry: TelemetryState
  readonly accessToken: string
  readonly tenantId: number
  readonly timeRange: TimeRange
  readonly globalThresholds: Threshold[]
  readonly localThresholds: Threshold[]
  readonly onOpenThresholds: (TelemetryType, assetId, assetName) => void
  readonly onOpenAssetInfo: (TelemetryType, asset, site) => void
  readonly fullWidthChart?: boolean
  readonly hideGaugeBreakpoint?: keyof BreakpointValues
  readonly allHistoricalData: HistoricalDataState
  readonly latestTelemetry: TelemetryMessage[]
}

type AllProps = Props & WithStyles<typeof styles>

class Sensors extends Component<AllProps> {
  public render() {
    const {
      site: { site },
    } = this.props

    return site ? this.renderAssets(site) : null
  }

  private renderAssets(site: Site) {
    return site ? (
      <Grid container={true} direction="column">
        {this.getAssets().map((metric, i) => (
          <Grid item={true} key={i} style={{ zIndex: i }}>
            {metric}
          </Grid>
        ))}
      </Grid>
    ) : null
  }

  private readonly getAssets = () => {
    const {
      site: { site },
      hideGaugeBreakpoint,
      allHistoricalData,
    } = this.props

    if (!site) {
      return []
    }

    const {
      telemetry: { telemetry },
      timeRange,
      accessToken,
      tenantId,
      localThresholds,
      globalThresholds,
      onOpenThresholds,
      onOpenAssetInfo,
      fullWidthChart,
      latestTelemetry,
    } = this.props

    const metrics = [
      ...this.getAssetComponents(
        telemetry,
        site,
        allHistoricalData.data,
        latestTelemetry
      ),
    ]

    return metrics.map(
      ({
        asset,
        realtimeData,
        Metric,
        historicalData,
        recentValue,
        param,
        telType,
      }) => (
        <Metric
          key={`${telType}_${asset.id}`}
          site={site}
          asset={asset}
          loading={!!allHistoricalData.loading}
          realtimeData={realtimeData}
          timeRange={timeRange}
          accessToken={accessToken}
          tenantId={tenantId}
          param={param}
          thresholds={this.getGlobalOrAlertThresholds(
            localThresholds,
            globalThresholds,
            asset.id,
            telType
          )}
          onOpenThresholds={
            this.isThresholdBtnVisible(telType) ? onOpenThresholds : undefined
          }
          onOpenAssetInfo={onOpenAssetInfo}
          fullWidthChart={fullWidthChart}
          hideGaugeBreakpoint={hideGaugeBreakpoint}
          historicalData={historicalData}
          initialLatestValue={recentValue}
        />
      )
    )
  }

  private getGlobalOrAlertThresholds(
    localThresholds: Threshold[],
    globalThresholds: Threshold[],
    assetId: string,
    telemetryType: TelemetryType
  ): StatusRange[] {
    const localThreshold = localThresholds.find((el) => el.AssetId === assetId)
    const globalThreshold = globalThresholds.find(
      (el) => el.AssetId === null || el.AssetId === undefined
    )

    return (
      localThreshold?.thresholds.get(telemetryType) ??
      globalThreshold?.thresholds.get(telemetryType) ??
      []
    )
  }

  private *getAssetComponents(
    telemetry: TelemetryByTelemetryTypeAndAssetId,
    site: Site,
    data: HistoricalDataByTelemetryTypeAndAssetId,
    latestTelemetry: TelemetryMessage[]
  ) {
    function recentValue(assetType: TelemetryType, assetId: string) {
      return latestTelemetry.find(
        (m) => m.TelemetryType === assetType && m.AssetId === assetId
      )
    }

    function pressureMetric(telemetryType: TelemetryType, title: string) {
      return (props) => new Pressure(props, telemetryType, title)
    }

    function metric(asset: Asset, telType: TelemetryType, metricComponent) {
      const realtimeData = telemetry[telType]?.get(asset.id)?.data ?? []
      const historicalData = {
        [telType]: data[telType]?.get(asset.id) ?? [],
      } as HistoricalDataByTelemetryType

      return {
        telType,
        realtimeData,
        historicalData,
        asset: {
          ...asset,
          name: asset.name ?? asset.id,
        },
        Metric: metricComponent,
        recentValue: recentValue(telType, asset.id),
        param: asset,
      }
    }

    function pumpControl(
      asset: Asset,
      telType: TelemetryType,
      metricComponent
    ) {
      const realtimeData = telemetry[telType]?.get(asset.id)?.data ?? []
      const historicalData = {
        [telType]: data[telType]?.get(asset.id) ?? [],
      } as HistoricalDataByTelemetryType

      return {
        telType,
        realtimeData,
        historicalData,
        asset: {
          ...asset,
          name: asset.name ?? asset.id,
        },
        Metric: metricComponent,
        recentValue: recentValue(telType, asset.id),
        param: asset,
      }
    }

    for (const pump of site.pumpControls) {
      yield pumpControl(pump, TelemetryType.PumpControl, PumpControl)
    }

    function pressure(asset: Asset, telType: TelemetryType, title) {
      return metric(asset, telType, pressureMetric(telType, title))
    }

    if (site.compressors.length > 0) {
      for (const vibration of site.vibrations) {
        yield metric(vibration, TelemetryType.Vibration, Vibration)
      }
    }

    for (const well of site.wells) {
      if (well.hasTubing) {
        yield pressure(well, TelemetryType.TubingPressure, 'Tubing Pressure')
      }

      if (well.hasCasing) {
        yield pressure(well, TelemetryType.CasingPressure, 'Casing Pressure')
      }

      if (well.hasCrank) {
        yield metric(well, TelemetryType.CrankRevolutions, CrankRevolutions)
      }

      if (well.hasPump) {
        yield pressure(well, TelemetryType.PumpPressure, 'Pump Pressure')
      }
    }

    for (const tank of site.tanks) {
      yield metric(tank, TelemetryType.TankLevel, TankLevel)
    }

    for (const balancedTank of site.BalancedTanks) {
      yield metric(balancedTank, TelemetryType.BalancedTank, BalancedTank)
    }

    for (const flowMeter of site.flowMeters) {
      const realtimeData = this.getFlowRealtimeTelemetry(flowMeter, telemetry)

      if (flowMeter.isTotalFlow) {
        yield {
          flowMeter,
          realtimeData,
          asset: {
            ...flowMeter,
            name: flowMeter.name ?? flowMeter.id,
          },
          Metric: Flow,
          historicalData: this.getTotalFlowHistoricalData(data, flowMeter.id),
          recentValue: recentValue(TelemetryType.TotalFlow, flowMeter.id),
          param: flowMeter,
          telType: TelemetryType.TotalFlow,
        }
      } else {
        yield {
          flowMeter,
          realtimeData,
          asset: {
            ...flowMeter,
            name: flowMeter.name ?? flowMeter.id,
          },
          Metric: Flow,
          historicalData: this.getFlowHistoricalData(data, flowMeter.id),
          recentValue: recentValue(TelemetryType.Flow, flowMeter.id),
          param: flowMeter,
          telType: TelemetryType.Flow,
        }
      }
    }

    for (const staticPressure of site.staticPressure) {
      const realtimeData = this.getLinePsiRealtimeTelemetry(
        staticPressure,
        telemetry
      )

      yield {
        flowMeter: staticPressure,
        realtimeData,
        asset: {
          ...staticPressure,
          name: staticPressure.name ?? staticPressure.id,
        },
        Metric: LinePsi,
        historicalData: this.getLinePsiHistoricalData(data, staticPressure.id),
        recentValue: recentValue(
          TelemetryType.StaticPressure,
          staticPressure.id
        ),
        param: staticPressure,
        telType: TelemetryType.StaticPressure,
      }
    }

    for (const knockout of site.knockoutPressures) {
      yield metric(knockout, TelemetryType.KnockoutPressure, KnockoutPressure)
    }

    for (const liquidFlow of site.liquidFlows) {
      yield metric(liquidFlow, TelemetryType.LiquidFlow, LiquidFlow)
    }

    for (const genericSensor of site.genericSensors) {
      yield metric(genericSensor, TelemetryType.GenericSensor, GenericSensor)
    }

    for (const SPMVol of site.strokesPerMinute) {
      yield metric(SPMVol, TelemetryType.StrokesPerMinute, StrokesPerMinute)
    }

    for (const heater of site.heaters) {
      yield metric(heater, TelemetryType.HeaterTemp, HeaterTemperature)
    }

    for (const separator of site.separators) {
      yield pressure(
        separator,
        TelemetryType.SeparatorPressure,
        'Separator Pressure'
      )
    }

    for (const compressor of site.compressors) {
      yield pressure(
        compressor,
        TelemetryType.CompressorPressure,
        'Compressor Pressure'
      )
    }

    for (const Flare of site.flaresStatus) {
      yield metric(Flare, TelemetryType.FlareStatus, FlareStatus)
    }
  }

  private getFlowHistoricalData(
    data: HistoricalDataByTelemetryTypeAndAssetId,
    assetId: string
  ) {
    const histTelemetryMessages: HistoricalDataByTelemetryType = {}

    for (const telemetryType of flowTelemetryTypes) {
      histTelemetryMessages[telemetryType] =
        data[telemetryType]?.get(assetId) ?? []
    }

    return histTelemetryMessages
  }

  private getTotalFlowHistoricalData(
    data: HistoricalDataByTelemetryTypeAndAssetId,
    assetId: string
  ) {
    const histTelemetryMessages: HistoricalDataByTelemetryType = {}

    for (const telemetryType of totalFlowTelemetryTypes) {
      histTelemetryMessages[telemetryType] =
        data[telemetryType]?.get(assetId) ?? []
    }

    return histTelemetryMessages
  }

  private getPumpHistoricalData(
    data: HistoricalDataByTelemetryTypeAndAssetId,
    assetId: string
  ) {
    const histTelemetryMessages: HistoricalDataByTelemetryType = {}

    for (const telemetryType of pumpTelemetryTypes) {
      histTelemetryMessages[telemetryType] =
        data[telemetryType]?.get(assetId) ?? []
    }

    return histTelemetryMessages
  }

  private getPumpControlTelemetry(
    pump: StorePumpControl,
    telemetryTypeDictionary: TelemetryByTelemetryTypeAndAssetId
  ): TelemetryMessage[] {
    let realtimeData: TelemetryMessage[] = []

    for (const telemetryType of pumpTelemetryTypes) {
      const currRealtimeData =
        telemetryTypeDictionary[telemetryType]?.get(pump.id)?.data || []

      if (currRealtimeData.length) {
        realtimeData = realtimeData.concat(currRealtimeData)
      }
    }
    return realtimeData
  }

  private getFlowRealtimeTelemetry(
    flowMeter: FlowMeter,
    telemetryTypeDictionary: TelemetryByTelemetryTypeAndAssetId
  ): TelemetryMessage[] {
    let realtimeData: TelemetryMessage[] = []

    for (const telemetryType of flowTelemetryTypes) {
      const currRealtimeData =
        telemetryTypeDictionary[telemetryType]?.get(flowMeter.id)?.data || []

      if (currRealtimeData.length) {
        realtimeData = realtimeData.concat(currRealtimeData)
      }
    }

    for (const telemetryType of totalFlowTelemetryTypes) {
      const currRealtimeData =
        telemetryTypeDictionary[telemetryType]?.get(flowMeter.id)?.data || []

      if (currRealtimeData.length) {
        realtimeData = realtimeData.concat(currRealtimeData)
      }
    }
    return realtimeData
  }

  private getPumpRealtimeTelemetry(
    pump: PumpControl,
    telemetryTypeDictionary: TelemetryByTelemetryTypeAndAssetId
  ): TelemetryMessage[] {
    let realtimeData: TelemetryMessage[] = []

    for (const telemetryType of pumpTelemetryTypes) {
      const currRealtimeData =
        telemetryTypeDictionary[telemetryType]?.get(pump.id)?.data || []

      if (currRealtimeData.length) {
        realtimeData = realtimeData.concat(currRealtimeData)
      }
    }
    return realtimeData
  }

  private getLinePsiHistoricalData(
    data: HistoricalDataByTelemetryTypeAndAssetId,
    staticPressureAssetId: string
  ) {
    const histTelemetryMessages: HistoricalDataByTelemetryType = {}

    for (const telemetryType of linePSITelemetryTypes) {
      histTelemetryMessages[telemetryType] =
        data[telemetryType]?.get(staticPressureAssetId) ?? []
    }

    return histTelemetryMessages
  }

  private getLinePsiRealtimeTelemetry(
    staticPressure: StaticPressure,
    telemetryTypeDictionary: TelemetryByTelemetryTypeAndAssetId
  ): TelemetryMessage[] {
    let realtimeData: TelemetryMessage[] = []

    for (const telemetryType of linePSITelemetryTypes) {
      const currRealtimeData =
        telemetryTypeDictionary[telemetryType]?.get(staticPressure.id)?.data ||
        []

      if (currRealtimeData.length) {
        realtimeData = realtimeData.concat(currRealtimeData)
      }
    }
    return realtimeData
  }

  private readonly isThresholdBtnVisible = (assetType: TelemetryType) =>
    assetType !== TelemetryType.CrankRevolutions
}

export default withStyles(styles)(Sensors)
