import React from 'react'
import memoize from 'memoize-one'
import theme from 'theme'

import { LinearProgress, IconButton, Tooltip } from '@material-ui/core'
import {
  TuneTwoTone,
  TableChartTwoTone,
  TimelineTwoTone,
  Info,
} from '@material-ui/icons'
import { BreakpointValues } from '@material-ui/core/styles/createBreakpoints'

import Sensor from './Sensor'
import MeasuresTable from '../details/MeasuresTable'
import { ModelState } from '../../store/ModelState'
import SensorChart, { Reference } from './SensorChart'
import {
  TelemetryType,
  TelemetryClient,
  TankLevelBalanceResponse,
} from '../../api/apiservice'
import { TelemetryMessage } from '../../store/SiteDetails'
import { AxisDomain } from 'recharts'
import { multitenantApiBasePath, getFetch } from '../../api/fetch'
import { HistoricalDataEntry, getTankLevelBalance } from '../../api/client'
import { TimeRange } from '../misc/TimeRange'
import { Asset, Site } from 'store/Site'
import { HistoricalDataByTelemetryType } from 'store/HistoricalData'
import { StatusRange, TelemetryStatus } from 'api/alertservice'

export interface Reading {
  readonly value?: number
  readonly rawValue?: number
  readonly formattedValue: React.ReactNode
  readonly formattedRawValue: React.ReactNode
  readonly timestamp?: Date
  readonly unitOfMeasure?: string
}

export interface SensorWithChartProps extends ModelState {
  readonly accessToken: string
  readonly tenantId: number
  readonly realtimeData: TelemetryMessage[]
  readonly timeRange: TimeRange
  readonly param?: any
  readonly thresholds: StatusRange[]
  readonly site: Site
  readonly asset: Asset
  readonly fullWidthChart?: boolean
  readonly historicalData: HistoricalDataByTelemetryType
  readonly initialLatestValue?: TelemetryMessage
}

interface ComponentState<T> extends ModelState {
  readonly gaugeReading: Reading
  readonly subState?: T
  readonly references?: Reference[]
  readonly referenceName?: string
  readonly isTableViewSelected: boolean
  readonly rawValue?: number
  readonly tankBalance?: TankLevelBalanceResponse
  readonly hiddenData: string[]
  readonly modalConfirmation: boolean
  readonly showSnackbar: boolean
  readonly snackbarMessage: string
  readonly snackbarSeverity: 'success' | 'error' | 'warning' | 'info'
  readonly user: any
  readonly pumpState: 'On' | 'Pending' | 'Off'
  readonly startHourBPD?: string
  readonly endHourBPD?: string
}

interface Props {
  readonly onOpenThresholds?: (TelemetryType, assetId, assetName) => void
  readonly onOpenAssetInfo?: (TelemetryType, asset, site) => void
  readonly hideGaugeBreakpoint?: keyof BreakpointValues
}

let previousStateProp: string[]

export type AllProps = Props & SensorWithChartProps

export default abstract class SensorWithChart<
  TSubState = {}
> extends React.Component<AllProps, ComponentState<TSubState>> {
  protected readonly TelemetryType: TelemetryType
  protected readonly Title: string
  protected readonly YAxisDomain?: [AxisDomain, AxisDomain]

  protected readonly memoizedData = memoize(
    (
      historicalDataEntries: HistoricalDataEntry[],
      realtimeDataEntries: TelemetryMessage[],
      rawValue?: number
    ): HistoricalDataEntry[] => {
      const historicalData: HistoricalDataEntry[] = historicalDataEntries ?? []

      const realtimeData = realtimeDataEntries.map((e) => {
        const isPumpType = this.TelemetryType === TelemetryType.PumpControl
        const isFlowType = this.TelemetryType === TelemetryType.TotalFlow
        const isPressureType =
          this.TelemetryType === TelemetryType.CasingPressure ||
          this.TelemetryType === TelemetryType.TubingPressure

        const data = {
          timestamp: e.CreationTimeUtc.valueOf(),
          value: isFlowType ? e.Payload.value.volumeFlowRate : e.Payload.value,
          rawValue: e.Payload.rawValue,
          unitOfMeasure: isPressureType ? e.Payload.unitOfMeasure : '',
          metaData: {},
          status: '',
        }

        if (isPumpType) {
          data.metaData = e.Payload.metaData
          data.status = e.Payload.value === 1 ? 'ON' : 'OFF'
        }

        return data
      })

      if (this.TelemetryType === TelemetryType.TotalFlow) {
        const historicalDataTotalFlow: HistoricalDataEntry[] = []
        historicalDataEntries.forEach((element) => {
          const histData = {
            timestamp: element.timestamp,
            value: parseFloat(element.value?.VolumeFlowRate.toString()) || 0,
            todayVolumeFlowed:
              parseFloat(element.value?.TodayVolumeFlowed.toString()) || 0,
            yesterdayVolumeFlowed:
              parseFloat(element.value?.YesterdayVolumeFlowed.toString()) || 0,
            staticPressure:
              parseFloat(element.value?.StaticPressure.toString()) || 0,
            values: element.value,
          }
          historicalDataTotalFlow.push(histData)
        })

        const data = historicalDataTotalFlow
          .concat(realtimeData)
          .map(this.processData)
          .sort((a, b) => a.timestamp - b.timestamp)

        return data
      }

      const data = historicalData
        .concat(realtimeData)
        .map(this.processData)
        .sort((a, b) => a.timestamp - b.timestamp)

      return data
    }
  )

  protected readonly memoizesThresholds = memoize(
    (thresholds: StatusRange[]): StatusRange[] =>
      this.processThresholds(thresholds)
  )

  constructor(
    props: AllProps,
    telemetryType: TelemetryType,
    title: string,
    yAxisDomain?: [AxisDomain, AxisDomain]
  ) {
    super(props)

    this.TelemetryType = telemetryType
    this.Title = title
    this.YAxisDomain = yAxisDomain

    this.state = {
      gaugeReading: {
        formattedValue: this.formatValue(),
        formattedRawValue: this.getRawValueFormatByType(),
      },
      isTableViewSelected: false,
      tankBalance: undefined,
      hiddenData: [],
    }
    previousStateProp = []
    this.updateState = this.updateState.bind(this)
  }

  public getHiddenData(historicalData): string[] {
    const currentTelemetry = Number(Object.keys(historicalData))
    if (historicalData[currentTelemetry] !== undefined) {
      const invalidIds = historicalData[currentTelemetry]
        .filter((x) => x.invalidData)
        .map((x) => x.id)
      return invalidIds
    }
    return []
  }

  public updateState = (id: string, validData: boolean): string[] => {
    let currentState: string[] = this.state.hiddenData

    currentState = currentState.reduce(function (acc: string[], curr) {
      if (!acc.includes(curr)) acc.push(curr)
      return acc
    }, [])

    if (validData) {
      const itemFound = this.state.hiddenData.findIndex((t) => t === id)
      if (itemFound !== undefined && itemFound !== -1) {
        currentState = currentState.filter((x) => x !== id)
      }
      currentState.push(id)

      this.setState({
        hiddenData: currentState,
      })
    } else {
      const itemFound = this.state.hiddenData.findIndex((t) => t === id)
      if (itemFound !== undefined && itemFound !== -1) {
        currentState = currentState.filter((x) => x !== id)
      }
      this.setState({
        hiddenData: currentState,
      })
    }
    return currentState
  }

  public async componentDidMount() {
    await this.load()
    await this.loadReferences()
  }

  public render() {
    const { gaugeReading } = this.state
    const content = this.renderContent(
      gaugeReading,
      this.props.initialLatestValue
    )

    return (
      <Sensor
        {...this.state}
        title={this.Title}
        subTitle={this.props.asset.name}
        value={gaugeReading?.formattedValue}
        timestamp={gaugeReading?.timestamp}
        content={content}
        titleExtension={this.getTitleExtension()}
        titleBarContent={this.getTitleContent()}
        {...this.props}
      >
        {this.getDataView()}
      </Sensor>
    )
  }

  protected getTitleExtension(): React.ReactNode | undefined {
    return undefined
  }

  private getDataView(): React.ReactNode {
    const { fullWidthChart, historicalData, realtimeData } = this.props
    const { rawValue } = this.state
    const hiddenHistoricalData = this.hideHistoricalData(
      historicalData,
      this.state.hiddenData
    )

    const renderView = this.state.isTableViewSelected
      ? this.renderTableView
      : this.renderChartView

    return renderView.bind(this)(
      hiddenHistoricalData,
      realtimeData,
      rawValue,
      fullWidthChart
    )
  }

  private hideHistoricalData(
    allHistoricalData: HistoricalDataByTelemetryType,
    hiddenData: string[]
  ): HistoricalDataByTelemetryType {
    const currentTelemetry = Number(Object.keys(allHistoricalData))

    if (hiddenData !== undefined && hiddenData.length === 0) {
      const invalidData = this.getHiddenData(allHistoricalData)
      if (previousStateProp.length === 0 && invalidData.length !== 0) {
        hiddenData = invalidData
        this.setState({
          hiddenData: invalidData,
        })
      }
    }

    if (
      allHistoricalData !== undefined &&
      allHistoricalData[currentTelemetry] !== undefined
    ) {
      allHistoricalData[currentTelemetry].forEach((element) => {
        if (hiddenData !== undefined) {
          if (hiddenData.some((x) => x === element.id)) {
            element.invalidData = true
          } else {
            element.invalidData = false
          }
        }
      })
    }
    return allHistoricalData
  }

  private renderChartView(
    historicalData: HistoricalDataByTelemetryType,
    realtimeData: TelemetryMessage[],
    rawValue?: number,
    fullWidthChart?: boolean
  ) {
    const currRealtime = this.filterByTelemetryTypeAndAssetId(
      realtimeData,
      this.TelemetryType,
      this.props.asset.id
    )
    const data =
      this.memoizedData(
        historicalData[this.TelemetryType]!,
        currRealtime,
        rawValue
      ) ?? []
    const { timeRange, thresholds } = this.props
    const enhancedThresholds = this.calculateThresholds(
      data,
      this.memoizesThresholds(thresholds)
    )
    const { references, referenceName } = this.state

    return (
      <SensorChart
        {...this.state}
        data={data}
        historicalData={historicalData}
        formatValue={this.formatValue}
        formatRawValue={this.getRawValueFormatByType}
        yAxisDomain={this.YAxisDomain}
        timeRange={timeRange}
        statusRanges={enhancedThresholds}
        fullWidth={fullWidthChart}
        references={references}
        referenceName={referenceName}
        updateState={this.updateState}
        hideData={this.state.hiddenData}
      />
    )
  }

  private filterByTelemetryTypeAndAssetId(
    data: TelemetryMessage[],
    telType: TelemetryType,
    assetId: string
  ) {
    return data.filter(
      (r) => r.TelemetryType === telType && r.AssetId === assetId
    )
  }

  private renderTableView(
    historicalData: HistoricalDataByTelemetryType,
    realtimeData: TelemetryMessage[],
    rawValue?: number,
    fullWidthChart?: boolean
  ) {
    const histAndRealtimeByTelType: HistoricalDataEntry[] = Object.entries(
      historicalData
    ).reduce((data, [key, value]) => {
      const currRealtime = this.filterByTelemetryTypeAndAssetId(
        realtimeData,
        Number(key),
        this.props.asset.id
      )

      return {
        ...data,
        [key]: this.memoizedData(
          historicalData[this.TelemetryType]!,
          currRealtime,
          rawValue
        ),
      }
    }, [])

    return (
      <MeasuresTable
        allHistoricalData={histAndRealtimeByTelType}
        hideData={this.state.hiddenData}
        fullWidth={fullWidthChart}
        loading={this.props.loading}
        updateState={this.updateState}
        {...this.state}
      />
    )
  }

  private referencesLoaded = false

  public componentDidUpdate(prevProps: SensorWithChartProps, prevState) {
    previousStateProp = prevState.hiddenData

    if (
      this.props.site.id !== prevProps.site.id ||
      this.props.timeRange !== prevProps.timeRange
    ) {
      this.load()
    } else if (
      this.TelemetryType === TelemetryType.LiquidFlow &&
      !this.referencesLoaded
    ) {
      this.loadReferences()
      this.referencesLoaded = true
    }

    const { realtimeData, initialLatestValue } = this.props

    if (this.state.gaugeReading.value === undefined && initialLatestValue) {
      // set value on initial load
      let initialLatestValueHistoryEntry
      if (this.TelemetryType === TelemetryType.TotalFlow) {
        initialLatestValueHistoryEntry = {
          timestamp: initialLatestValue.CreationTimeUtc.valueOf(),
          value: initialLatestValue.Payload?.value?.volumeFlowRate || 0,
        }
      } else {
        initialLatestValueHistoryEntry = {
          timestamp: initialLatestValue.CreationTimeUtc.valueOf(),
          value: initialLatestValue.Payload?.value,
          unitOfMeasure: initialLatestValue.Payload?.unitOfMeasure,
        }
      }
      const mostRecentReading = this.getMostRecentReading(
        initialLatestValueHistoryEntry
      )
      this.setState({
        gaugeReading: mostRecentReading,
      })
      return
    }

    const hasNewRealtimeData =
      realtimeData.length !== prevProps.realtimeData.length

    if (hasNewRealtimeData) {
      if (realtimeData.length === 0) {
        this.setState({
          gaugeReading: {
            formattedValue: this.formatValue(),
            formattedRawValue: this.getRawValueFormatByType(),
          },
        })
        return
      }

      const flowRealtimeData = realtimeData.filter(
        (m) => m.TelemetryType === this.TelemetryType
      )
      const newRealtimeData = flowRealtimeData.pop()

      if (
        newRealtimeData &&
        this.TelemetryType === newRealtimeData.TelemetryType
      ) {
        const newRealtimeDataHistoryEntry = {
          timestamp: newRealtimeData.CreationTimeUtc.valueOf(),
          value: newRealtimeData.Payload.value,
          rawValue: newRealtimeData.Payload.rawValue,
        }
        const mostRecentReading = this.getMostRecentReading(
          newRealtimeDataHistoryEntry
        )

        this.setState({
          gaugeReading: mostRecentReading,
        })
        return // eslint-disable-line no-useless-return
      }
    }
  }

  protected abstract renderContent(
    reading: Reading,
    latestTelemetry?: TelemetryMessage
  ): React.ReactNode

  protected formatValue(value?: any, unitOfMeasure?: any): React.ReactNode {
    return (
      <b>
        {typeof value === 'number'
          ? `${value.toFixed(1)} ${unitOfMeasure}`
          : 'N/A'}
      </b>
    )
  }

  protected formatRawValue = (value?: any): React.ReactNode => {
    return (
      <b>
        {typeof value === 'number' ? `${value.toFixed(0)} (Raw Value)` : 'N/A'}
      </b>
    )
  }

  private readonly getRawValueFormatByType = (value?: any) => {
    switch (this.TelemetryType) {
      case TelemetryType.TotalFlow:
      case TelemetryType.Flow:
        return undefined

      default:
        return this.formatRawValue(value)
    }
  }

  protected processData(data: HistoricalDataEntry): HistoricalDataEntry {
    return data
  }

  protected async load() {
    await this.loadMostRecentValue()
    await this.loadReferences()
    await this.loadTankBalance()
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected async loadReferences(refTimeRange?: TimeRange) {}

  protected processThresholds(thresholds: StatusRange[]): StatusRange[] {
    return thresholds
  }

  private async loadTankBalance() {
    const { accessToken, tenantId, site, asset } = this.props
    let result

    if (this.TelemetryType === TelemetryType.TankLevel) {
      result = await getTankLevelBalance(
        accessToken,
        tenantId.toString(),
        site.id,
        asset.id
      )
      this.setState({
        tankBalance: result,
      })
    }
  }

  private async loadMostRecentValue() {
    const { accessToken, tenantId } = this.props

    try {
      let result

      if (this.TelemetryType === TelemetryType.TotalFlow) {
        result = await new TelemetryClient(
          multitenantApiBasePath,
          getFetch(accessToken, tenantId.toString())
        ).getLatestTelemetryValuesGET(
          this.TelemetryType,
          this.props.site.id,
          this.props.asset.id
        )
      } else {
        result = await new TelemetryClient(
          multitenantApiBasePath,
          getFetch(accessToken, tenantId.toString())
        ).getLatestTelemetryValuesGET(
          this.TelemetryType,
          this.props.site.id,
          null,
          this.props.asset.id
        )
      }

      const mostRecentValue: Reading = {
        timestamp: result.creationTimeUtc,
        value:
          this.TelemetryType === TelemetryType.TotalFlow
            ? result.payload?.value?.volumeFlowRate
            : result.payload?.value,
        rawValue: result.result?.payload?.rawValue,
        formattedValue: this.formatValue(
          this.TelemetryType === TelemetryType.TotalFlow
            ? result.payload?.value?.volumeFlowRate
            : result.payload?.value
        ),
        formattedRawValue: this.getRawValueFormatByType(
          result.result?.payload?.rawValue
        ),
        unitOfMeasure: result.result?.payload.unitOfMeasure,
      }

      this.setState({
        gaugeReading: mostRecentValue,
        rawValue: result.result?.payload?.rawValue,
      })
    } catch (error) {
      console.warn(
        `Failed to load most recent value for telemetry ${
          TelemetryType[this.TelemetryType]
        }`,
        error
      )

      this.setState({
        gaugeReading: {
          formattedValue: this.formatValue(),
          formattedRawValue: this.getRawValueFormatByType(),
        },
      })
    }
  }

  private getMostRecentReading(mostRecentValue?: HistoricalDataEntry): Reading {
    if (mostRecentValue) {
      const { gaugeReading: prevMostRecentValue } = this.state

      const newMostRecentValue = (value: HistoricalDataEntry) => ({
        value: value.value,
        formattedValue: this.formatValue(value.value, value.unitOfMeasure),
        formattedRawValue: this.getRawValueFormatByType(),
        timestamp: value.timestamp ? new Date(value.timestamp) : undefined,
        unitOfMeasure: value.unitOfMeasure,
      })

      return mostRecentValue.timestamp >
        (prevMostRecentValue.timestamp?.valueOf() ?? 0)
        ? newMostRecentValue(mostRecentValue)
        : prevMostRecentValue
    } else {
      return { formattedValue: 'N/A', formattedRawValue: 'N/A' }
    }
  }

  private readonly getTitleContent = (): React.ReactNode => {
    const getTelemetryTypeFormula = (asset: any) => {
      switch (this.TelemetryType) {
        case TelemetryType.TubingPressure:
          return asset.tubingPressure?.telemetryTypeFormula
        case TelemetryType.CasingPressure:
          return asset.casingPressure?.telemetryTypeFormula
        case TelemetryType.CrankRevolutions:
          return asset.crankRevolutions?.telemetryTypeFormula
        case TelemetryType.PumpPressure:
          return asset.pumpPressure?.telemetryTypeFormula

        default:
          return asset.telemetryTypeFormula
      }
    }

    const renderAssetInfoButton =
      getTelemetryTypeFormula(this.props.asset) &&
      this.TelemetryType !== TelemetryType.FlareStatus &&
      this.TelemetryType !== TelemetryType.PumpControl
    const renderViewButton = this.TelemetryType !== TelemetryType.FlareStatus

    return (
      <React.Fragment>
        {renderAssetInfoButton && this.getAssetInfo()}
        {this.TelemetryType !== TelemetryType.Vibration &&
          this.TelemetryType !== TelemetryType.PumpControl &&
          this.getThresholds()}
        {renderViewButton && this.getViewButton()}
      </React.Fragment>
    )
  }

  private readonly getAssetInfo = (): React.ReactNode => {
    const { onOpenAssetInfo } = this.props

    if (!onOpenAssetInfo) {
      return null
    }

    return (
      <Tooltip title="Show asset information">
        <IconButton
          onClick={() =>
            onOpenAssetInfo(
              this.TelemetryType,
              this.props.asset,
              this.props.site
            )
          }
          size="small"
        >
          <Info />
        </IconButton>
      </Tooltip>
    )
  }

  private readonly getThresholds = (): React.ReactNode => {
    if (this.state.loading) {
      return (
        <LinearProgress
          style={{
            width: '100%',
            marginLeft: theme.spacing(1),
          }}
        />
      )
    }

    const { onOpenThresholds } = this.props

    if (!onOpenThresholds) {
      return null
    }

    return (
      <Tooltip title="Thresholds">
        <IconButton
          onClick={() =>
            onOpenThresholds(
              this.TelemetryType,
              this.props.asset.id,
              this.props.asset.name ?? this.props.asset.id
            )
          }
          size="small"
        >
          <TuneTwoTone />
        </IconButton>
      </Tooltip>
    )
  }

  private readonly getViewButton = (): React.ReactNode => {
    const { isTableViewSelected } = this.state

    let tooltipView: string, icon: React.ReactNode
    if (isTableViewSelected) {
      tooltipView = 'chart'
      icon = <TimelineTwoTone />
    } else {
      tooltipView = 'table'
      icon = <TableChartTwoTone />
    }

    return (
      <Tooltip title={`Switch to ${tooltipView} view`}>
        <IconButton
          onClick={() =>
            this.setState({
              isTableViewSelected: !isTableViewSelected,
            })
          }
          size="small"
        >
          {icon}
        </IconButton>
      </Tooltip>
    )
  }

  private readonly calculateThresholds = (
    data: HistoricalDataEntry[],
    statusThresholds: StatusRange[]
  ): StatusRange[] => {
    const statusesToShow = [
      TelemetryStatus.Yellow,
      TelemetryStatus.Red,
      TelemetryStatus.Green,
    ]
    const thresholdViewModels = statusThresholds.filter((th) =>
      statusesToShow.includes(th.status)
    )

    // Extending ranges to draw RED area instead of non-colored when actual value is greater than max / lesser then min thresholds.
    if (data?.length && thresholdViewModels?.length) {
      const extraRange = 20
      const maxThresholdValue = thresholdViewModels.reduce(
        (to, current) => Math.max(to, current.maxThreshold),
        thresholdViewModels[0].maxThreshold
      )
      const maxDataValue = data.reduce(
        (max, current) => Math.max(max, current.value),
        data[0].value as number
      )
      if (maxDataValue > maxThresholdValue) {
        const threshold = {
          status: TelemetryStatus.Red,
          minThreshold: maxThresholdValue,
          maxThreshold: maxDataValue + extraRange,
        }
        thresholdViewModels.push(StatusRange.fromJS(threshold))
      }
      const minThresholdValue = thresholdViewModels.reduce(
        (from, current) => Math.min(from, current.minThreshold),
        thresholdViewModels[0].minThreshold
      )
      const minDataValue = data.reduce(
        (min, current) => Math.min(min, current.value),
        data[0].value
      )
      if (minDataValue < minThresholdValue) {
        const threshold = {
          status: TelemetryStatus.Red,
          minThreshold: minDataValue - extraRange,
          maxThreshold: minThresholdValue,
        }
        thresholdViewModels.push(StatusRange.fromJS(threshold))
      }
    }
    return thresholdViewModels
  }
}
