import React from 'react'

import { TelemetryTypeToStatusRanges } from '../../../api/thresholdClient'
import { TelemetryThresholdsComponentState } from './TelemetryThresholds'
import TelemetryThresholdsWithChartPreview from './TelemetryThresholdsWithChartPreview'
import { Props as SensorChartProps } from 'components/sensors/SensorChart'
import { TelemetryType, StatusRange } from 'api/alertservice'
import { Site } from '../../../store/Site'

export interface Props {
  readonly siteId: string
  readonly assetId: string
  readonly allThresholds: TelemetryTypeToStatusRanges
  readonly type?: TelemetryType
  readonly site?: Site
  readonly stateUpdated: (
    allowSave: boolean,
    onStateUpdated?: () => void
  ) => void
  readonly sensorChartProps?: SensorChartProps
}

type AllProps = Props

interface State extends Partial<TelemetryThresholdsComponentState> {
  readonly allThresholds: TelemetryTypeToStatusRanges
}

const telemetryTypesToDisplay = [
  TelemetryType.TubingPressure,
  TelemetryType.CasingPressure,
  TelemetryType.PumpPressure,
  TelemetryType.TankLevel,
  TelemetryType.SeparatorPressure,
  TelemetryType.CompressorPressure,
  TelemetryType.LiquidFlow,
  TelemetryType.GenericSensor,
  TelemetryType.StaticPressure,
  TelemetryType.StrokesPerMinute,
  TelemetryType.TotalFlow,
  TelemetryType.KnockoutPressure,
]

export default class ThresholdConfiguration extends React.Component<
  AllProps,
  State
> {
  constructor(props: AllProps) {
    super(props)

    this.state = {
      allThresholds: new Map<TelemetryType, StatusRange[]>(
        props.allThresholds.entries()
      ),
    }
  }

  public componentDidMount() {
    this.loadThresholds()
  }

  public componentDidUpdate(prevProps: AllProps) {
    if (
      !this.state.allThresholds.size ||
      this.props.allThresholds.size !== prevProps.allThresholds.size
    ) {
      this.loadThresholds()
    }
  }

  public save(
    onSaved: (state: Partial<TelemetryThresholdsComponentState>) => void
  ) {
    const { allowSave, type, statusRanges: thresholds } = this.state

    this.props.stateUpdated(!!allowSave, () =>
      onSaved({
        allowSave,
        type,
        statusRanges: thresholds,
      })
    )
  }

  public render() {
    return (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        {this.renderEditThresholdsView()}
      </div>
    )
  }

  private renderEditThresholdsView() {
    const { type, allThresholds, sensorChartProps, siteId, assetId, site } =
      this.props

    const thresholds =
      type && !allThresholds.has(type)
        ? new Map<TelemetryType, StatusRange[]>(
            [...allThresholds.entries()].concat([[type, []]])
          )
        : allThresholds

    return Array.from(thresholds)
      .sort((a, b) => a[0] - b[0])
      .filter(([telemetryType, _]) => !type || type === telemetryType)
      .map(([key, thresholds]) => (
        <TelemetryThresholdsWithChartPreview
          key={key}
          site={site}
          telemetryType={type}
          siteId={siteId}
          assetId={assetId}
          type={key}
          thresholds={thresholds}
          stateUpdated={this.stateUpdated}
          sensorChartProps={sensorChartProps}
        />
      ))
  }

  private loadThresholds() {
    const allThresholds = new Map<TelemetryType, StatusRange[]>(
      this.props.allThresholds.entries()
    )
    this.matchThresholdsWithRequired(allThresholds)
    this.setState({ allThresholds })
  }

  private matchThresholdsWithRequired(thresholds: TelemetryTypeToStatusRanges) {
    telemetryTypesToDisplay.forEach((type) => {
      const thresholdsForSpecificType = thresholds.get(type)

      if (thresholdsForSpecificType === undefined) {
        thresholds.set(type, [] as StatusRange[])
      }
    })
  }

  private readonly stateUpdated = (
    state: TelemetryThresholdsComponentState
  ) => {
    const allowSave = !!state.allowSave

    this.props.stateUpdated(allowSave)

    this.setState({ ...this.state, ...state })
  }
}
