import React from 'react'

import { StatusRange, TelemetryType } from 'api/alertservice'
import TankLevelThresholdsTextValidator from './TankLevelThresholdsTextValidator'
import DefaultThresholdsTextValidation from './DefaultThresholdsTextValidator'
import { StatusThresholdMinMaxProperties } from './ThresholdsTextValidatorBase'
import HeaterTempThresholdsValidation from './HeaterTempThresholdsTextValidation'

export interface ThresholdsTextValidatorProps {
  telemetryType: TelemetryType
  name: string
  value: any
  ruleName: string
  ruleMessage: string
  editedThreshold?: StatusRange
  index: number
  property: keyof StatusThresholdMinMaxProperties
  handleThresholdChange(
    stringValue: string,
    index: number,
    property: keyof StatusThresholdMinMaxProperties
  ): void
  checkIntersectingRule(minThreshold: number, maxThreshold: number): boolean
}

type AllProps = ThresholdsTextValidatorProps

class ThresholdsTextValidator extends React.Component<AllProps> {
  public render() {
    const { telemetryType, ...otherProps } = this.props

    const Component = this.getComponentType(telemetryType)

    if (Component === null) {
      return null
    }

    return <Component {...otherProps} />
  }

  private readonly getComponentType = (telemetryType: TelemetryType) => {
    switch (telemetryType) {
      case TelemetryType.TankLevel:
      case TelemetryType.BalancedTank:
        return TankLevelThresholdsTextValidator
      case TelemetryType.TubingPressure:
      case TelemetryType.CasingPressure:
      case TelemetryType.PumpPressure:
      case TelemetryType.SeparatorPressure:
      case TelemetryType.CompressorPressure:
      case TelemetryType.LiquidFlow:
      case TelemetryType.GenericSensor:
      case TelemetryType.StaticPressure:
      case TelemetryType.Flow:
      case TelemetryType.StrokesPerMinute:
      case TelemetryType.KnockoutPressure:
      case TelemetryType.TotalFlow:
        return DefaultThresholdsTextValidation
      case TelemetryType.HeaterTemp:
        return HeaterTempThresholdsValidation
      default:
        return null
    }
  }
}

export default ThresholdsTextValidator
